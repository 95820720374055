import Typography from '@/components/core/Typography'
import appStoreSrc from '@/public/img/app_store.webp'
import playStoreSrc from '@/public/img/play_store.webp'
import qrSrc from '@/public/img/qr-prod.webp'
import Image from 'next/image'
import Link from 'next/link'

import styles from './StoreLogos.module.scss'

type StoreLogsProps = {
  className?: string
}

const StoreLogos = ({ className }: StoreLogsProps) => (
  <div className={className}>
    <Typography variant="h5" as="h4" className={styles.title}>
      Take Your Insights On-the-Go!
    </Typography>
    <div className={styles.apps}>
      <div className={styles.qr_code}>
        <Image src={qrSrc} alt="QR code" width="103" height="103" unoptimized />
      </div>
      <Link
        target="_blank"
        href="https://apps.apple.com/us/app/pulse-q-a/id1440075320"
        aria-label="Go to the Apple's Appstore"
        className={styles.app_store}
      >
        <Image src={appStoreSrc} alt="App store" width="135" height="40" unoptimized />
      </Link>
      <Link
        target="_blank"
        href="https://play.google.com/store/apps/details?id=qa.pulse.app"
        aria-label="Go to the Google's Playstore"
        className={styles.play_store}
      >
        <Image src={playStoreSrc} alt="Play store" width="180" height="53" unoptimized />
      </Link>
    </div>
  </div>
)

export default StoreLogos
