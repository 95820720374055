'use client'

import SearchIcon from '@/components/icons/SearchIcon'

import clsx from 'clsx'
import type { FormEventHandler } from 'react'
import styles from './SearchForm.module.scss'

type SearchFormProps = {
  placeholder?: string
  onSubmit?: FormEventHandler<HTMLFormElement>
  className?: string
}

const SearchForm = ({
  placeholder = 'Search for insights, advice, and more',
  onSubmit = () => {},
  className,
}: SearchFormProps) => (
  <form action="/peer-community/search" className={clsx(styles.form, className)} onSubmit={onSubmit}>
    <input name="text" className={styles.input} placeholder={placeholder} />
    <SearchIcon />
  </form>
)

export default SearchForm
